// styles.css
 
.image-code {
    padding: 10px;
    user-select: none;
}
 
.image-container {
    position: relative;
    background-color: #ddd;
}
 
.canvas {
    position: absolute;
    top: 0;
    left: 0;
}
 
.reload-container {
    margin: 20px 0;
}
 
.reload-wrapper {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}
 
.reload-ico {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background: center/cover no-repeat;
}
 
.reload-tips {
    font-size: 14px;
    color: #666;
}
 
.slider-wrpper {
    position: relative;
    margin: 10px 0;
}
 
.slider-bar {
    padding: 10px;
    font-size: 14px;
    text-align: center;
    color: #999;
    background-color: #ddd;
}
 
.slider-button {
    position: absolute;
    top: 50%;
    left: 0;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    transform: translateY(-50%);
    cursor: pointer;
    background: #fff center/80% 80% no-repeat;
    box-shadow: 0 2px 10px 0 #333;
}
 
/* 提示信息 */
.tips-container,
.tips-container--active {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    padding: 10px;
    transform: translate(-50%, -50%);
    transition: all 0.25s;
    background: #fff;
    border-radius: 5px;
 
    visibility: hidden;
    opacity: 0;
}
 
.tips-container--active {
    visibility: visible;
    opacity: 1;
}
 
.tips-ico {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background: center/cover no-repeat;
}
 
.tips-text {
    color: #666;
}

.login-modal-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 61px;

    .login-modal-title{
        font-size: 22px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 46px;
        letter-spacing: 3px;
        padding-bottom: 24px;
    }
}

.login-input-2 input{
    padding-right: 120px;
}

.post-code-box{
    color: #0090FF;
    transform: translate(72%,-56px);
    cursor: pointer;                              
}