* {
    margin: 0;
    padding: 0;
}

ul {
    list-style: none;
}

video::-webkit-media-controls {
    display: none !important;
}

.ant-tooltip{
    max-width: 100vw !important;
}


.ant-modal-content{
    box-shadow: 0px 2px 44px 0px rgba(0, 0, 0, 0.38) !important;
    border-radius: 8px !important;
}

.ant-form-explain{
    transform: translateY(8px);
    transition-property:all;
}

.ant-modal-header{
    border-radius:8px 8px 0 0 !important;
}

html {
    font-size: 100px;
}
a{
    // color: #fff;
    text-decoration: none !important;
}

body {
    font-size: 0.14rem;
    background: #fafbff !important;
    min-width: 1170px;
    height: 100vh;
}
.loading{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 999;
}
@media screen and (max-width: 1169px) and (min-width: 769px) {
    html {
        font-size: 60.94px;
        // font-size: 13.33333vw;
    }
    body {
        width: 100%;
        min-width: 100%;
    }
}
@media screen and (max-width: 768px) and (min-width: 721px) {
    html {
        font-size: 102.4px;
        font-size: 13.33333vw;
    }
    body {
        width: 100%;
        min-width: 100%;
    }
    .loading{
        left: 40%;
    }
}
@media screen and (max-width: 720px) and (min-width: 641px) {
    html {
        font-size: 96px;
        font-size: 13.33333vw;
    }
    body {
        width: 100%;
        min-width: 100%;
    }
    .loading{
        left: 40%;
    }
}
@media screen and (max-width: 640px) and (min-width: 541px) {
    html {
        font-size: 85.33px;
        font-size: 13.33333vw;
    }
    body {
        width: 100%;
        min-width: 100%;
    }
    .loading{
        left: 40%;
    }
}
@media screen and (max-width: 540px) and (min-width: 481px) {
    html {
        font-size: 72px;
        font-size: 13.33333vw;
    }
    body {
        width: 100%;
        min-width: 100%;
    }
    .loading{
        left: 40%;
    }
}
@media screen and (max-width: 480px) and (min-width: 415px) {
    html {
        font-size: 64px;
        font-size: 13.33333vw;
    }
    body {
        width: 100%;
        min-width: 100%;
    }
    .loading{
        left: 40%;
    }
}
@media screen and (max-width: 414px) and (min-width: 413px) {
    html {
        font-size: 55.2px;
        font-size: 13.33333vw;
    }
    body {
        width: 100%;
        min-width: 100%;
    }
    .loading{
        left: 40%;
    }
}
@media screen and (max-width: 412px) and (min-width: 394px) {
    html {
        font-size: 54.93px;
        font-size: 13.33333vw;
    }
    body {
        width: 100%;
        min-width: 100%;
    }
    .loading{
        left: 40%;
    }
}
@media screen and (max-width: 393px) and (min-width: 376px) {
    html {
        font-size: 52.4px;
        font-size: 13.33333vw;
    }
    body {
        width: 100%;
        min-width: 100%;
    }
    .loading{
        left: 40%;
    }
}
@media screen and (max-width: 375px) and (min-width: 361px) {
    html {
        font-size: 50px;
        font-size: 13.33333vw;
    }
    body {
        width: 100%;
        min-width: 100%;
    }
    .loading{
        left: 40%;
    }
}
@media screen and (max-width: 360px) and (min-width: 321px) {
    html {
        font-size: 48px;
        font-size: 13.33333vw;
    }
    body {
        width: 100%;
        min-width: 100%;
    }
    .loading{
        left: 40%;
    }
}
@media screen and (max-width: 320px) {
    html {
        font-size: 42.667px;
        font-size: 13.33333vw;
    }
    body {
        width: 100%;
        min-width: 100%;
    }
    .loading{
        left: 40%;
    }
}
