
.vertifyBox{
  background: #FFFFFF;
  box-shadow: 0px 2px 17px 0px rgba(24, 138, 255, 0.41);
  padding: 16px 24px;
  position: relative;
}

.vertifyTitle{
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
  padding-bottom: 12px;
}

.vertifyClose{
  position: absolute;
  right: 16px;
  top: 12px;
  font-size: 20px;
  color: #D4DDED;
  cursor: pointer;
}

.vertifyWrap {
  position: relative;
}
.vertifyWrap .block {
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  cursor: grab;
}
.vertifyWrap .block:active {
  cursor: grabbing;
}
.vertifyWrap .sliderContainer {
  position: relative;
  text-align: center;
  width: 310px;
  height: 30px;
  line-height: 30px;
  margin-top: 25px;
  /* background: #f7f9fa; */
  color: #45494c;
  /* border: 1px solid #e4e7eb; */
}
/* .vertifyWrap .sliderContainer_active .slider {
  height: 38px;
  top: -1px;
  border: 1px solid #486cd6;
} */
/* .vertifyWrap .sliderContainer_active .sliderMask {
  height: 38px;
  border-width: 1px;
} */
/* .vertifyWrap .sliderContainer_success .slider {
  height: 38px;
  top: -1px;
  border: 1px solid #0db87f;
  background-color: #0ca14a !important;
}
.vertifyWrap .sliderContainer_success .sliderMask {
  height: 38px;
  border: 1px solid #0db87f;
  background-color: #d2f4ef;
}
.vertifyWrap .sliderContainer_success .sliderIcon {
  background-position: 0 -26px !important;
}
.vertifyWrap .sliderContainer_fail .slider {
  height: 38px;
  top: -1px;
  border: 1px solid #f57a7a;
  background-color: #f57a7a !important;
}
.vertifyWrap .sliderContainer_fail .sliderMask {
  height: 38px;
  border: 1px solid #f57a7a;
  background-color: #fce1e1;
}
.vertifyWrap .sliderContainer_fail .sliderIcon {
  top: 14px;
  background-position: 0 -82px !important;
} */

.sliderText{
  padding-top: 16px;
}
.vertifyWrap .sliderContainer_active .sliderText,
.vertifyWrap .sliderContainer_success .sliderText,
.vertifyWrap .sliderContainer_fail .sliderText {
  display: none;
}
.vertifyWrap .sliderMask {
  position: absolute;
  left: 0;
  top: 0;
  height: 10px;
  width: 100%;
  border-radius: 5px;
  border: 0 solid #486cd6;
  background: #d1e9fe;
}
.vertifyWrap .slider {
  position: absolute;
  top: 0;
  left: 0;
  /* width: 40px; */
  height: 30px;
  background: #fff;
  /* box-shadow: 0 0 3px rgba(0, 0, 0, 0.3); */
  transition: background 0.2s linear;
  cursor: pointer;
  cursor: grab;
  transform: translateY(-10px);
}
.vertifyWrap .slider:active {
  cursor: grabbing;
}
/* .vertifyWrap .slider:hover {
  background: #486cd6;
} */
.vertifyWrap .sliderIcon {
  font-size: 18px;
  color: #fff;
  letter-spacing: 6px;
  text-indent:4px;
  background: linear-gradient(180deg, #00C3FF 0%, #0090FF 100%);
  box-shadow: 0px 3px 14px 0px rgba(8, 107, 183, 0.44);
  border-radius: 15px;
  width: 57px;
  height: 30px;
  line-height: 30px;
}
/* .vertifyWrap .slider:hover .sliderIcon {
  color: #fff;
} */
.vertifyWrap .refreshIcon {
  cursor: pointer;
  color: #9BA4A9;
  font-size: 20px;
  text-align: right;
  opacity: .6;
}
.vertifyWrap .loadingContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 310px;
  height: 155px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #45494c;
  z-index: 2;
  background: #edf0f2;
}
.vertifyWrap .loadingIcon {
  width: 32px;
  height: 32px;
  margin-bottom: 10px;
  background: url(http://cdn.dooring.cn/dr/icon12.png);
  background-size: 32px;
  animation: loading-icon-rotate 0.8s linear infinite;
}
@keyframes loading-icon-rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
