.header-page {
    height: 0.74rem;
    display: flex;
    width: 100%;
    min-width: 11.7rem;
    justify-content: space-between;
    position: fixed;
    z-index: 999;
    text-align: center;
    .logo {
        margin: 0.1rem 0.1rem 0.1rem 0.3rem;
        width: auto;
        height: 0.59rem;
    }
    .right {
        text-align: center;
        height: 100%;
        flex-shrink: 0;
        min-width: 9rem;
        .list {
            display: flex;
            position: relative;
            .caret-down {
                position: absolute !important;
                z-index: 999;
            }
            .title {
                text-align: center;
                padding: 0.25rem 0.2rem 0.15rem 0.3rem;
                color: #fff;
                height: 100%;
                font-size: 0.18rem;
                font-weight: 400;
                line-height: 0.18rem;

                &:nth-child(2) {
                    padding-right: 0.4rem;
                }
                &:hover .hover {
                    color: #0090ff;
                }
                .hover {
                    height: 0.34rem;
                    display: inline-block;
                    color: rgba(255, 255, 255, 1);
                    -webkit-transform: translateZ(0);
                    transform: translateZ(0);
                    box-shadow: 0 0 0.01rem rgba(0, 0, 0, 0);
                    -webkit-backface-visibility: hidden;
                    backface-visibility: hidden;
                    -moz-osx-font-smoothing: grayscale;
                    position: relative;
                    &::after {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        left: 50%;
                        right: 50%;
                        bottom: 0;
                        background: #0090ff;
                        height: 0.02rem;
                        -webkit-transition-property: left, right;
                        transition-property: left, right;
                        -webkit-transition-duration: 0.3s;
                        transition-duration: 0.3s;
                        -webkit-transition-timing-function: ease-out;
                        transition-timing-function: ease-out;
                    }
                }
                .hover:hover:after,
                .hover:focus:after,
                .hover:active:after {
                    left: 0;
                    right: 0;
                }
            }
            .num-outer {
                padding: 0.15rem 0.3rem 0.1rem 0.3rem;
                color: #fff;
                height: 100%;
                .num {
                    color: #fff;
                    display: flex;
                    width: 1.5rem;
                    height: 0.4rem;
                    padding: 0.05rem 0px 0.02rem 0.05rem;
                    justify-content: flex-start;
                    align-items: center;
                    text-align: left;
                    border: 1px solid #fff;
                    border-radius: 0.3rem;
                    .phone {
                        padding: 0.1rem;
                        font-size: 0.18rem;
                    }
                    p {
                        display: inline-block;
                        font-size: 0.12rem;
                        line-height: 0.14rem;
                        margin: 0rem;
                    }
                }
            }
            .financial:hover .down-list {
                display: block;
            }
            .financial:hover {
                padding-right: 0.4rem;
            }
            .login:hover .logindown-list{
                display: block;
            }
            .login:hover .logindownall{
                display: block;
            }

                //登陆模块样式

                .line{
                    margin-right: 0.5rem;
                }
                .logindown-list{
                    height: 0.58rem !important;
                }

                .llogindownall{
                    height: 1.16rem;
                }

                .logindown-list ,.logindownall{
                    background-color: cornflowerblue;
                    z-index: 999;
                    position: absolute;
                    display: none;
                    flex-wrap: wrap;
                    left: 8rem;
                    width: 2.3rem;
                    // height: 1.16rem;
                    background: rgba(24, 39, 66, 0.8);
                    box-shadow: 0rem 0.13rem 0.15rem 0rem rgba(34, 36, 53, 0.27); 
                   
                    .item {
                        width: 2.3rem;
                        height: 0.54rem;
                        text-align: center;
                        border-bottom: 0.005rem solid rgba(255, 255, 255, 0.1);
                        &:last-child {
                            border-bottom: none;
                        }

                        .item-item {
                            font-size: 0.14rem;
                            width: 2rem;
                            margin: 0 auto;
                            height: 100%;
                            color: rgba(255, 255, 255, 0.8);
                            line-height: 0.54rem;
                            display: flex;
                            justify-content: center;
                            align-content: center;

                            i {
                                margin: auto 0px;
                            }
                        }

                        &:hover {
                            background: #182742;
                        }
                    }

                    .item:hover .item-item {
                        color: #0090ff;
                    }
                }
            .active {
                .a {
                    border-bottom: 0.02rem solid #fff;
                    color: #fff;
                    height: 0.34rem;
                    display: inline-block;
                }
            }
            .active:hover a {
                color: #0090ff;
            }
            .down-list {
                z-index: 999;
                position: absolute;
                display: none;
                flex-wrap: wrap;
                left: 0.55rem;
                width: 2.3rem;
                height: 2.7rem;
                background: rgba(24, 39, 66, 0.8);
                box-shadow: 0rem 0.13rem 0.15rem 0rem rgba(34, 36, 53, 0.27);
                .item {
                    width: 2.3rem;
                    height: 0.54rem;
                    text-align: center;
                    border-bottom: 0.005rem solid rgba(255, 255, 255, 0.1);
                    &:last-child {
                        border-bottom: none;
                    }
                    .item-item {
                        font-size: 0.14rem;
                        width: 2rem;
                        margin: 0 auto;
                        height: 100%;
                        color: rgba(255, 255, 255, 0.8);
                        line-height: 0.54rem;
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        i {
                            margin: auto 0px;
                        }
                    }
                    &:hover {
                        background: #182742;
                    }
                }
                .item:hover .item-item {
                    color: #0090ff;
                }
            }
        }
    }
    .horizontal-line-box {
        display: none;
    }
    .phone-down-list {
        display: none;
    }
    .prompt-box {
        display: none;
    }
}
.show {
    background: rgba(14, 22, 38, 0.9);
}
.prompt-box-block {
    display: block;
    position: fixed;
    right: 10px;

    ul {
        li {
            cursor: pointer;
            text-align: center;
            padding: 15px 0px;
            width: 75px;
            height: 80px;
            p {
                font-size: 0.12rem;
                color: rgba(34, 36, 53, 1);
                line-height: 17px;
            }
        }
    }
}
.header-block {
    display: block;
    display: flex;
}
.header-none {
    display: none;
}
@media (max-width: 1170px) {
    .header-page .right .list .num-outer {
        padding-top: 0.1rem;
        .num {
            width: 2rem;
            height: 0.5rem;
            p {
                line-height: 0.2rem;
            }
        }
    }
}
@media (max-width: 768px) {
    body {
        width: 100%;
        min-width: 100%;
    }

    .header-page {
        min-width: 100%;
        height: 0.98rem;
        background: #0e1626;
        position: fixed;
        .logo {
            width: auto;
            height: 0.5rem;
            margin: 0.23rem 0.1px 0.21rem 0.27rem;
        }
        .right {
            display: none;
        }
        .horizontal-line-box {
            display: block;
            width: 0.3rem;
            height: 15px;
            position: absolute;
            right: 0.4rem;
            top: 0.3rem;
            .horizontal-line {
                display: block;
                width: 0.3rem;
                height: 0.049rem;
                background: #fff;
                margin-bottom: 0.06rem;
            }
            .chahao {
                font-size: 0.3rem;
                color: #fff;
            }
            .line-animation {
                .line {
                    display: block;
                    width: 0.3rem;
                    height: 0.049rem;
                    background: #fff;
                    margin-bottom: 0.06rem;
                }
            }
        }
        .phone-down-list {
            position: absolute;
            display: none;
            // animation: block1 0.5s linear 0s 1 both;
            top: 0.98rem;
            width: 4.9rem;
            height: 100vh;
            background: #0e1626;
            padding: 0px 0.28rem 0px 0.7rem;
            .phone-down-list-title {
                width: 100%;
                color: #fff;
                display: block;
                text-align: left;
                height: 0.85rem;
                line-height: 0.85rem;
                font-size: 0.28rem;
                border-bottom: 0.01rem solid rgba(255, 255, 255, 0.3);
                a {
                    color: #fff;
                }
            }
            .phone-down-list-title-list {
                width: 100%;
                color: #fff;
                display: block;
                text-align: left;
                border-bottom: 0.01rem solid rgba(255, 255, 255, 0.3);
                a {
                    color: #fff;
                }
                .phone-down-list-title-home {
                    display: inline-block;
                    font-size: 0.22rem;
                    line-height: 0.9rem;
                    height: 0.75rem;
                    text-align: center;

                    color: rgba(255, 255, 255, 0.6);
                }
                .item-item {
                    &:last-child {
                        .item {
                            margin-bottom: 0.4rem;
                        }
                    }
                }
                .item {
                    font-size: 0.28rem;
                    line-height: 0.68rem;
                }
            }
            .num-outer {
                color: #fff;
                height: 0.7rem;
                line-height: 0.7rem;
                margin-top: 0.5rem;
                a {
                    color: #fff;
                }
                .num {
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    position: relative;
                    font-size: 0.24rem;
                    .phone {
                        width: 0.11rem;
                        height: 0.11rem;
                        position: absolute;
                        top: 0.26rem;
                    }
                    .phone-num {
                        margin-left: 0.5rem;
                        a {
                            color: #fff;
                        }
                    }
                }
            }
        }
        .phone-down-list-block {
            display: block;
            animation: block1 0.5s linear 0s 1 both;
        }
        .phone-down-list-none {
            display: none;
            // animation: none1 0.5s linear 0s 1 both;
        }
        @keyframes block1 {
            from {
                opacity: 0;
                right: -4.9rem;
            }
            to {
                right: 0rem;
                opacity: 1;
            }
        }
        @-moz-keyframes block1 {
            from {
                opacity: 0;
                right: -4.9rem;
            }
            to {
                right: 0rem;
                opacity: 1;
            }
        }
        @-webkit-keyframes block1 {
            from {
                opacity: 0;
                right: -4.9rem;
            }
            to {
                right: 0rem;
                opacity: 1;
            }
        }
        @-o-keyframes block1 {
            from {
                opacity: 0;
                right: -4.9rem;
            }
            to {
                right: 0rem;
                opacity: 1;
            }
        }
        @keyframes none1 {
            from {
                right: 0rem;
                opacity: 1;
            }
            to {
                opacity: 0;
                right: -4.9rem;
            }
        }
        @-moz-keyframes none1 {
            from {
                opacity: 0;
                right: -4.9rem;
            }
            to {
                right: 0rem;
                opacity: 1;
            }
        }
        @-webkit-keyframes none1 {
            from {
                opacity: 0;
                right: -4.9rem;
            }
            to {
                right: 0rem;
                opacity: 1;
            }
        }
        @-o-keyframes none1 {
            from {
                opacity: 0;
                right: -4.9rem;
            }
            to {
                right: 0rem;
                opacity: 1;
            }
        }
    }

    .prompt-box-block {
        display: none;
    }
}
