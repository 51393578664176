.footer-box {
    height: 2.34rem !important;
    background-image: url('/images/footerbg.png') !important;
    background-size: 100% 100%;
    .content {
        width: 11.7rem;
        max-width: 11.7rem;
        min-width: 11.7rem;
        height: 1.74rem;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
            display: flex;
            img {
                width: 1rem;
                height: 0.9rem;
                margin-right: 0.1rem;
            }
            .footer-text {
                height: 1.02rem;
                padding-top: 0.07rem;
                p {
                    margin: 0px;
                }
                .footer-title {
                    font-size: 0.18rem;
                    margin-bottom: 0.11rem;
                    text-align: left;
                    font-weight: 400;
                    color: white;
                    line-height: 0.33rem;
                }
                .e-mail {
                    text-align: left;
                    font-size: 0.14rem;
                    font-weight: 400;
                    color: white;
                    line-height: 0.2rem;
                    opacity: 0.6;
                }
            }
        }
        .right {
            display: flex;
            .zixun {
                width: 0.8rem;
                height: 0.9rem;
                margin-right: 0.15rem;
            }
            .text {
                text-align: left;
                margin-right: 0.2rem;
                width: 1.2rem;
                .phone {
                    font-size: 0.18rem;
                    color: rgba(255, 255, 255, 1);
                    line-height: 0.33rem;
                }
                .num {
                    font-size: 0.14rem;
                    font-weight: 400;
                    color: rgba(255, 255, 255, 1);
                    line-height: 0.08rem;
                    opacity: 0.6;
                    white-space: nowrap;
                }
            }
            .font {
                min-width: 1rem;
            }
            .attention {
                display: flex;
                width: 1.25rem !important;
                height: 1rem !important;
                padding: 0.1rem !important;
                justify-content: center;
                background: #63666a;
                align-items: center;
                .text {
                    margin: 0 auto;
                    margin-top: 0.03rem;
                    font-size: 0.12rem;
                    font-weight: 400;
                    color: rgba(255, 255, 255, 1);
                    line-height: 0.16rem;
                }
                .erweima {
                    flex-shrink: 0;
                    width: 0.82rem !important;
                    height: 0.82rem !important;
                }
            }
        }
    }
    .fter {
        width: 100%;
        min-width: 11.7rem;
        height: 0.6rem;
        background-color: #212630;
        display: flex;
        justify-content: center;
        align-items: center;
        .record {
            color: rgba(255, 255, 255, 0.56);
            font-size: 0.14rem;
            img {
                width: 0.2rem;
                height: 0.2rem;
                margin: 0px 0.05rem 0px 0.2rem;
            }
            span {
                margin-left: 0.2rem;
            }
        }
    }
}
.phone-footer {
    display: none;
}
.footer-none {
    display: none;
}
@media (max-width: 1170px) {
    .footer .footer-box .content {
        .left .footer-text .e-mail {
            line-height: 0.25rem;
        }
        .right {
            //    .attention .text {
            //        font-size: .08rem;
            //        line-height: 0.19rem;
            //    }
        }
    }
}
@media (max-width: 768px) {
    .footer-box {
        display: none;
    }
    .phone-footer {
        width: 100%;
        height: 4.47rem;
        background: #000;
        display: block;
        .phone-footer-top {
            height: 2.6rem;
            width: 100%;
            background-image: url('/images/phone/phone_footer_top.png');
            background-size: 100% 100%;
            display: flex;
            justify-content: space-between;
            padding: 0.7rem 0.6rem;
            .phone-top-text {
                font-size: 0.26rem;
                color: rgba(255, 255, 255, 1);
                line-height: 0.37rem;
                .phone {
                    font-weight: 600;
                }
                .num {
                    font-weight: 400;
                    line-height: 0.3rem;
                    color: rgba(255, 255, 255, 0.6);
                }
            }
            img {
                width: 1.13rem;
                height: 1.2rem;
            }
        }
        .phone-footer-bottom {
            // height: calc(100% - 2.62rem);
            width: 100%;
            background-image: url('/images/phone/phone_footer_bottom.jpg');
            background-size: 100% 100%;
            .public {
                display: flex;
                justify-content: space-between;
                padding: 0.7rem 0.6rem;
                font-size: 0.28rem;
                color: rgba(255, 255, 255, 1);
                line-height: 0.37rem;
                font-weight: 600;
                height: 2.18rem;
                .guanzhu {
                    font-size: 0.25rem;
                    font-weight: 400;
                    color: rgba(255, 255, 255, 0.6);
                }
                img {
                    width: 1.15rem;
                    height: 1.15rem;
                }
            }
            .phone-footer-bottom-bottom {
                height: 1.86rem;
                width: 100%;
                background: #000;
                padding-top: 0.38rem;

                .company_name {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 0.24rem;
                    font-weight: 500;
                    color: rgba(255, 255, 255, 1);
                    margin-bottom: 0.07rem;
                    img {
                        width: 0.5rem;
                        height: 0.44rem;
                        margin-right: 0.2rem;
                    }
                    p {
                        margin: 0px;
                        padding: 0px;
                    }
                }
                span {
                    display: block;
                    width: 100%;
                    margin: 0px;
                    text-align: center;
                    font-size: 0.2rem;
                    font-weight: 400;
                    color: rgba(255, 255, 255, 0.6);
                    line-height: 0.28rem;
                }
            }
        }
    }
}
